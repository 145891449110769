<template>
  <v-row title="">
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Events</v-card-title>
        <v-card>
          <v-col cols="12">
            <v-alert
              v-show="alert.show"
              :color="alert.color"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1 justify-right">
                    {{ alert.message }}
                  </p>
                  <a
                    align="right"
                    href="javascript:void(0)"
                    title="Hide this message"
                    :class="alert.class"
                    @click.prevent="hideAlert"
                  >
                    <span class="text-sm">{{ alert.callToAction }}</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <!-- loading icon -->
          <div
            v-show="isLoading"
            id="misc"
          >
            <div class="page-title text-center justify-center px-5">
              <div class="misc-character d-flex justify-center">
                <v-img
                  max-width="100"
                  margin="100"
                  src="@/assets/images/misc/loading-spinner-dark.gif"
                ></v-img>
              </div>
            </div>
          </div>

          <v-data-table
            v-show="!isLoading"
            :items="events"
            :headers="computedHeaders"
            :search="search"
            item-key="id"
            class="elevation-1"
            xxx-sort-by="type"
            :sort-by="['time']"
            :sort-desc="[true, false]"
            xxx-multi-sort
            xxx-group-by="type"
            xxx-show-group-by
            title=""
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-icon class="text--primary me-3">
                    {{ icons.mdiMagnify }}
                  </v-icon>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showUUIDs"
                  label="Detailed View"
                  class="mt-2"
                ></v-switch>
                <v-spacer></v-spacer>
                <!-- <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click.prevent="createControl"
                >
                  New Control
                </v-btn> -->
              </v-toolbar>
              <!-- <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h6">
                    Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      outlined
                      text
                      @click="deleteItemConfirm"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      outlined
                      text
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
            </template>
            <!-- <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
              <v-icon
                v-if="item.status !== 'LOCKED'"
                small
                class="mr-2"
                title="Details"
                @click="editItem(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
              <v-icon
                v-if="item.status !== 'LOCKED' && !item.value.includes('quasr.io') && item.value !== 'openid'"
                small
                title="Delete Control"
                @click="deleteItem(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template> -->
            <template v-slot:item.time="{ item }">
              <span>{{ item.time ? new Date(item.time).toLocaleString() : '' }}</span>
            </template>
            <template v-slot:item.type="{ item }">
              <span>{{ item.type.toLowerCase() }}</span>
            </template>
            <template v-slot:item.result="{ item }">
              <span>{{ result[item.result] }}</span>
            </template>
            <template v-slot:item.reason="{ item }">
              <span>{{ item.reason.toLowerCase() }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiMagnify,
  mdiPencilOutline, mdiDeleteOutline,
  mdiEyeOutline,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'
import gql from 'graphql-tag'
import { listEvents } from '../../graphql/queries'
import store from '../../store'

/* eslint-disable */
export default {
  name: 'Events',
  data() {
    return {
      search: '',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      dialog: false,
      headers: [
        {
          text: 'Event ID',
          align: 'start',
          sortable: true,
          filterable: true,
          value: 'id',
        },
        {
          text: 'Time',
          sortable: true,
          filterable: true,
          value: 'time',
        },
        {
          text: 'Type',
          sortable: true,
          filterable: true,
          value: 'type',
        },
        {
          text: 'Origin',
          sortable: true,
          filterable: true,
          value: 'origin',
        },
        {
          text: 'Action',
          sortable: true,
          filterable: true,
          value: 'action',
        },
        {
          text: 'Result',
          sortable: true,
          filterable: true,
          value: 'result',
        },
        {
          text: 'Reason',
          sortable: true,
          filterable: true,
          value: 'reason',
        },
        {
          text: 'Account',
          sortable: true,
          filterable: true,
          value: 'account_id',
        },
        {
          text: 'Detail',
          sortable: true,
          filterable: true,
          value: 'detail',
        },
      ],
      events: [],
      event: {},
    }
  },

  /**
   * computed
   */
  computed: {
    /**
     * computedHeaders
     */
    computedHeaders () {
      return this.headers.filter((h) => (!['id', 'source', 'reason', 'detail'].includes(h.value)) || this.showUUIDs)
    },
  },


  /**
   * watch
   */
  watch: {
    showUUIDs() {
      localStorage.setItem('showUUIDs', this.showUUIDs)
    },
    dialog (val) {
      val || this.close()
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)

    // display message if available
    if (store.message) {
      const msg = store.message.text.concat('')
      this.showAlert(msg, store.message.action, store.message.color)
      store.message = undefined
    } else {
      this.showAlert('Currently only the latest 100 events are shown and updated every 15 minutes.', 'OK', 'info')
    }

    await this.getEvents()
    this.isLoading = false
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const result = {
      'SUCCESS': 'Success',
      'PENDING': 'Pending',
      'FAILED': 'Failed',
    }
    const showUUIDs = localStorage.getItem('showUUIDs') ? localStorage.getItem('showUUIDs') === 'true' : false
    const isLoading = true
    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      showUUIDs,
      isLoading,
      result,
      loginStatus,
      icons: {
        mdiMagnify,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
      },
    }
  },

  /**
   * methods
   */
  methods: {
    async getEvents() {
      const events = await store.getAppSyncClient().query({ query: gql(listEvents), variables: { limit: 1000 } })
      this.events = events.data.listEvents.items
    },
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },
    hideAlert() {
      this.alert.show = false
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
/* eslint-enable */
</script>
